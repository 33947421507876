var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("errorContainer", { attrs: { error: _vm.erreur } }, [
    _c(
      "div",
      { staticClass: "animated fadeIn" },
      [
        _vm.loadContracts
          ? _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { lg: "12" } },
                  _vm._l(_vm.myContracts, function (item) {
                    return _c("contract", {
                      key: item.id,
                      attrs: {
                        "contract-input": item,
                        "disable-edit": !_vm.isFilmSingle,
                        accordion: _vm.accordion,
                        "hide-send-package-btn": false,
                        "show-pay-code": false,
                        "fill-class-container": "container-layout",
                      },
                      on: {
                        erreur: function ($event) {
                          _vm.erreur = $event
                        },
                        "contract:show-body": _vm.handleContractShowBody,
                      },
                    })
                  }),
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _c(
          "b-modal",
          {
            staticClass: "modal-success",
            attrs: {
              "header-class": "header-class-modal-doc-package",
              title: _vm.FormMSG(130, "Success!"),
              "ok-variant": "success",
              "ok-only": "",
              "cancel-title": "close",
              "modal-class": "mui-animation",
              fade: false,
            },
            on: {
              ok: function ($event) {
                _vm.successModal = false
              },
            },
            model: {
              value: _vm.successModal,
              callback: function ($$v) {
                _vm.successModal = $$v
              },
              expression: "successModal",
            },
          },
          [
            _vm._v(
              "\n\t\t\t" +
                _vm._s(_vm.FormMSG(120, "Your information has been saved.")) +
                "\n\t\t"
            ),
          ]
        ),
        _c("loading", {
          attrs: { active: _vm.isLoading, "is-full-page": true },
          on: {
            "update:active": function ($event) {
              _vm.isLoading = $event
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }